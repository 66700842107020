import React from 'react'
import ScrollToTop from '../components/ScrollToTop'
import Contactus from '../components/Contactus'

const ContactUsPage = () => {
  return (
    <>
      <ScrollToTop/>
      <Contactus/>
    </>
  )
}

export default ContactUsPage