// undraw.co to get svg images

export const homeObjOne = {
    id: 'about',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: '',
    headline: 'About Us',
description: 'AgniCAD Enterprises specializes in fire plan drafting, fire consultancy, Fire Evacuation Plan and fire NOC assistance. With over 10 years of experience and expertise in fire safety, we ensure comprehensive solutions tailored to your needs. Understand our process and how we deliver top-notch fire safety solutions.',
    buttonLabel: 'Get Started',
    imgStart: false,
    img: require('../../images/svg-2.svg').default,
    alt: 'GugaAbout',
    dark: true,
    primary: true,
    darkText: false
}

export const homeObjTwo = {
    id: 'discover',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Discover AgniCAD',
    headline: 'Protecting Lives With Precison',
    description: 'Explore our services and see how AgniCAD can help you prepare fire plans and evacuation plans adhering to statutory compliance.',
    buttonLabel: 'Learn More',
    imgStart: true,
    img: require('../../images/svg-1.svg').default,
    alt: 'GugaDiscover',
    dark: false,
    primary: false,
    darkText: true
}

export const homeObjThree = {
    id: 'signup',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Join our Team',
    headline: 'Experience AgniCAD',
    description: "Sign up for a new account today and receive free initial consultation on fire safety.",
    buttonLabel: 'Start Now',
    imgStart: false,
    img: require('../../images/svg-3.svg').default,
    alt: 'GugaSignUp',
    dark: false,
    primary: false,
    darkText: true
}

export const homeObjFour = {
    id: 'ourHistory',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Our History',
    headline: 'Transforming blueprints into lifelines',
    description: "AgniCAD Enterprises stands at the forefront of architectural precision. For over 4 years, our team of ‘AutoCAD certified’ fire engineers led the industry with our unwavering commitment to precision and quality that exceed expectations.",
    buttonLabel: 'Learn More',
    imgStart: false,
    img: require('../../images/svg-3.svg').default,
    alt: 'GugaSignUp',
    dark: false,
    primary: false,
    darkText: true
}