import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const Container = styled.div`
    min-height: 692px;
    background: radial-gradient(circle, rgba(243,149,64,1) 20%, rgba(61,13,1,1) 90%, rgba(0,0,0,1) 100%); 
`

export const FormWrap = styled.div`
    max-width:1000px;
    margin:0 auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding:20px 0px;
    @media screen and (max-width: 400px) {
        height: 80%;
    }
`

export const Icon = styled(Link)`
    text-decoration: none;
    color: #fff;
    font-weight: 700;
    font-size: 32px;
    margin: 20px 0px 20px 10px;
    text-align:center;
`

export const ContactInfo = styled.div`
    display:flex;
    justify-content:space-between;
    @media screen and (max-width:800px) {
        flex-direction:column;
    }

`

export const FormContent = styled.div`
    padding: 10px 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
`
export const BlackDiv = styled.div`
    background: #010101;
    max-width:1000px;
    height: auto;
    width: 100%;
    z-index: 1;
    display: grid;
    margin: 0 auto;
    padding: 20px 32px;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.9);

    @media screen and (max-width: 400px) {
        padding: 20px 32px;
    }
`
export const Form = styled.form`
    background: #010101;
    max-width:1000px;
    height: auto;
    width: 100%;
    z-index: 1;
    display: grid;
    margin: 0 auto;
    padding: 30px 32px;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.9);

    @media screen and (max-width: 400px) {
        padding: 32px 32px;
    }
`

export const FormH1 = styled.h1`
    margin-bottom: 40px;
    color: #fff;
    font-size: 20px;
    font-weight: 400;
`
export const Heading = styled.h1`
    margin-bottom: 40px;
    color: #fff;
    font-size: 20px;
    font-weight: 500;
`
export const Item = styled.h2`
display:flex;
align-items:center;
    margin-bottom: 10px;
    color: #fff;
    font-size: 18px;
    font-weight: 400;
`

export const FormLabel = styled.label`
    margin-bottom: 8px;
    font-size: 14px;
    color: #fff;
`

export const FormInput = styled.input`
    padding: 16px 16px;
    margin-bottom: 32px;
    border: none;
    border-radius: 4px;
`

export const FormMultilineInput = styled.input`
    min-height : 100px;
    padding: 16px 16px;
    margin-bottom: 32px;
    border: none;
    border-radius: 4px;
`

export const FormButton = styled.button`
    background: #FB6D07;
    padding: 16px 0;
    border: none;
    border-radius: 4px;
    color: #fff;
    font-size: 20px;
    cursor: pointer;
`

export const Text = styled.span`
    text-align: center;
    margin-top: 24px;
    color: #fff;
    font-size: 14px;
`