import React from 'react'
import Icon1 from '../../images/svg-1.svg'
import Icon2 from '../../images/svg-2.svg'
import Icon3 from '../../images/svg-3.svg'
import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesH2,
  ServicesP
} from './ServicesElements'
import Slider from 'react-slick'

const Services = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <ServicesContainer id='services'>
      <ServicesH1> Our Services </ServicesH1>
         <ServicesWrapper>
          <Slider {...settings}>
            <ServicesCard>
              <ServicesIcon src={Icon1}/>
              <ServicesH2> Fire Plan Drafting </ServicesH2>
              <ServicesP> Detailed and compliant fire plans for residential, commercial, and industrial properties. (AutoCAD, Revit-3D) </ServicesP>
            </ServicesCard>
            <ServicesCard>
              <ServicesIcon src={Icon2}/>
              <ServicesH2> 3D Fire Modelling </ServicesH2>
              <ServicesP> Advanced fire modeling using Pyrosim. </ServicesP>
            </ServicesCard>
            <ServicesCard>
              <ServicesIcon src={Icon3}/>
              <ServicesH2> Fire Evacuation Plan </ServicesH2>
              <ServicesP> Comprehensive evacuation plans to ensure safety during emergencies. </ServicesP>
            </ServicesCard>
            <ServicesCard>
              <ServicesIcon src={Icon2}/>
              <ServicesH2> Fire Consultancy </ServicesH2>
              <ServicesP> Expert advice and guidance on fire safety measures and compliance. </ServicesP>
            </ServicesCard>
            <ServicesCard>
              <ServicesIcon src={Icon1}/>
              <ServicesH2> Fire NOC Assistance </ServicesH2>
              <ServicesP>  Assistance with obtaining Fire No Objection Certificates (NOCs) from authorities.  Access the list of approved government fire consultants [here](#). </ServicesP>
            </ServicesCard>
            </Slider>
       </ServicesWrapper>
    </ServicesContainer>
  )
}

export default Services