import React from 'react'
import { FaFacebook, FaTwitter, FaInstagram , FaWhatsapp, FaEnvelope, FaPhone } from 'react-icons/fa'
import {
    FooterContainer,
    FooterWrap,
    FooterLinksContainer,
    FooterLinkItems,
    FooterLinksWrapper,
    FooterLinkTitle,
    FooterLink,
    SocialMedia,
    SocialMediaWrap,
    SocialLogo,
    SocialIcons,
    WebsiteRights,
    SocialIconLink,
    FooterLinkItems2,
    FooterLink2
} from './FooterElements'
import { animateScroll as scroll } from 'react-scroll'

// To link outside of the website, use normal a tags

const Footer = () => {

    // Function from react-scroll
    const toggleHome = () => {
        scroll.scrollToTop();
    }

    return (
        <FooterContainer>
            <FooterWrap>
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <FooterLinksContainer>
                        <FooterLinksWrapper>
                            <FooterLinkItems>
                                <FooterLinkTitle> About us </FooterLinkTitle>
                                <FooterLink to="/"> How it works </FooterLink>
                                <FooterLink to="/"> Testimonials </FooterLink>
                                <FooterLink to="/"> Careers </FooterLink>
                                <FooterLink to="/"> Terms of Service </FooterLink>
                            </FooterLinkItems>
                            <FooterLinkItems2>
                                <FooterLinkTitle> Videos </FooterLinkTitle>
                                <FooterLink to="/">Watch our informative videos on fire safety and our services.</FooterLink>
                            </FooterLinkItems2>
                        </FooterLinksWrapper>
                    </FooterLinksContainer>
                    <FooterLinksContainer>
                        <FooterLinksWrapper>
                            <FooterLinkItems2>
                                <FooterLinkTitle> Contact us </FooterLinkTitle>
                                <FooterLink2 to="mailto:info@agnicad.com"><FaEnvelope />&nbsp; info@agnicad.com</FooterLink2><FooterLink2 to="mailto:sonal@agnicad.com"><FaEnvelope />&nbsp; sonal@agnicad.com</FooterLink2>
                                <FooterLink2 to="tel:+91-9644440504"><FaPhone />&nbsp; +91-9644440504</FooterLink2>
                            </FooterLinkItems2>
                        </FooterLinksWrapper>
                    </FooterLinksContainer>
                </div>
                <SocialMedia>
                    <SocialMediaWrap>
                        <SocialLogo to='/' onClick={toggleHome}>
                            <img src='Agnicad-logo.png' alt='logo' width={110} style={{ marginTop: '-10px' }} />
                        </SocialLogo>
                        <WebsiteRights> AgniCAD Enterprises © {new Date().getFullYear()} All rights reserved. </WebsiteRights>
                        <SocialIcons>
                            <SocialIconLink href="https://www.facebook.com/profile.php?id=61562997156659" target="_blank" aria-label="Facebook">
                                <FaFacebook />
                            </SocialIconLink>
                            <SocialIconLink href="https://www.instagram.com/agnicad_team/?hl=en" target="_blank" aria-label="Instagram">
                                <FaInstagram />
                            </SocialIconLink>
                            <SocialIconLink href="https://wa.me/9644440504" target="_blank" aria-label="Whatsapp">
                                <FaWhatsapp />
                            </SocialIconLink>
                            <SocialIconLink href="https://x.com/AgniCAD" target="_blank" aria-label="Twitter">
                                <FaTwitter />
                            </SocialIconLink>
                        </SocialIcons>
                    </SocialMediaWrap>
                </SocialMedia>
            </FooterWrap>
        </FooterContainer>
    )
}

export default Footer