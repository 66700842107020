import React from 'react'
import {
    Container,
    FormWrap,
    Icon,
    FormContent,
    Form,
    FormH1,
    FormLabel,
    FormInput,
    FormButton,
    FormMultilineInput,
    BlackDiv,
    Heading,
    Item,
    ContactInfo
} from '../Signin/SigninElements'
import { FaEnvelope, FaPhone } from 'react-icons/fa'
import { FooterLink2 } from '../Footer/FooterElements'

const Contactus = () => {
    return (
        <Container>
            <FormWrap>
                <Icon to="/"> <img src='Agnicad-logo.png' alt='logo' width={110} /> </Icon>
                   <FormContent>
                        <BlackDiv>
                            <Heading> Contact Info : </Heading>
                            <ContactInfo>
                            <FooterLink2 to="mailto:info@agnicad.com"><Item><FaEnvelope color='#FB6D07'/>&nbsp; info@agnicad.com</Item></FooterLink2>
                            <FooterLink2 to="mailto:sonal@agnicad.com"><Item><FaEnvelope color="#FB6D07"/>&nbsp; sonal@agnicad.com</Item></FooterLink2>
                            <FooterLink2 to="tel:+91-9644440504"><Item><FaPhone color='#FB6D07'/>&nbsp; +91-9644440504</Item></FooterLink2>
                            </ContactInfo>
                        </BlackDiv>
                    </FormContent>
                    <FormContent>
                        <Form action="#">
                            <FormH1> Get in touch with us for any inquiries or support. </FormH1>
                            <FormLabel htmlFor='for'> Full Name</FormLabel>
                            <FormInput type='text' required />
                            <FormLabel htmlFor='for'> Email Address</FormLabel>
                            <FormInput type='email' required />
                            <FormLabel htmlFor='for'> Phone Number </FormLabel>
                            <FormInput type='number' required />
                            <FormLabel htmlFor='for'> Message</FormLabel>
                            <FormMultilineInput type='text' required />
                            <FormButton type='submit'> Submit </FormButton>
                        </Form>
                    </FormContent>
            </FormWrap>
        </Container>
    )
}

export default Contactus