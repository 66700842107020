import React from 'react'
import Icon1 from '../../images/svg-1.svg'
import Icon2 from '../../images/svg-2.svg'
import Icon3 from '../../images/svg-3.svg'
import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesH2,
  ServicesP
} from '../Services/ServicesElements'
import Slider from 'react-slick'

const WhyAgnicad = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <ServicesContainer id='whyAgnicad'>
      <ServicesH1> Why AgniCAD </ServicesH1>
         <ServicesWrapper>
          <Slider {...settings}>
            <ServicesCard>
              <ServicesIcon src={Icon1}/>
              <ServicesH2> Fire Safety Expertise </ServicesH2>
              <ServicesP> Leading-edge fire planning tailored to meet stringent safety standards. </ServicesP>
            </ServicesCard>
            <ServicesCard>
              <ServicesIcon src={Icon2}/>
              <ServicesH2> Cost-Effective Solutions </ServicesH2>
              <ServicesP> Benefit from our competitive rates while maintaining top-notch quality. </ServicesP>
            </ServicesCard>
            <ServicesCard>
              <ServicesIcon src={Icon3}/>
              <ServicesH2> Unrivaled Experience </ServicesH2>
              <ServicesP> Over 4 years of setting new benchmarks in project execution. </ServicesP>
            </ServicesCard>
            <ServicesCard>
              <ServicesIcon src={Icon2}/>
              <ServicesH2> Partner in Progress </ServicesH2>
              <ServicesP> From concept to completion, ensuring safety and efficiency. </ServicesP>
            </ServicesCard>
            </Slider>
       </ServicesWrapper>
    </ServicesContainer>
  )
}

export default WhyAgnicad